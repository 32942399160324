import React, {useRef, useState} from "react";
import {Dimensions, Image, Platform, ScrollView, StyleSheet, TouchableOpacity, View,} from "react-native";
import {programs} from "./mockData";
import Title from "../../components/Title";
import HTML from "react-native-render-html";
import playButton from '../../assets/playbutton.png';
import {Video} from 'expo-av';
import {Ionicons} from "@expo/vector-icons";
import useTranslate from "../../hooks/useTranslate";
import BackButton from "../../components/BackButton";
import colors from "../../colors/colors";
import PageTitle from "../../components/text/PageTitle";
import textColors from "../../colors/textColors";
import GradientImage from "../../components/GradientImage";

const styles = StyleSheet.create({
    container: {
        width: '100%',
        paddingHorizontal: 16
    },
    playbutton: {
        width: 50,
        height: 50,
        alignSelf: 'center',
    },
    videoContainer: {
        ...StyleSheet.absoluteFillObject,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#000000BB',

    },
    videoElement: {
        width: '100%',
        maxWidth: 600,
        height: 240
    },
    videoControls: {
        marginTop: 16,
        paddingHorizontal: 12,
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
    }
});

const ExerciseDetailScreen = ({route}) => {
    const {programId, workoutId} = route.params;
    const [showVideo, setShowVideo] = useState(false);

    const videoElementRef = useRef(null);
    const exercise = programs
        .find((program) => program.id === programId)
        .workouts.find((workout) => workout.id === workoutId).exercise;
    return (
        <View style={{flex: 1, backgroundColor: colors.accent}}>
            <TouchableOpacity
                style={{width: '100%'}}
                onPress={() => setShowVideo(true)}>
                <GradientImage image={exercise.imagePath}/>
                <View style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <Image source={playButton} style={styles.playbutton}/>
                </View>
            </TouchableOpacity>
            <BackButton/>
            <ScrollView style={styles.container}>
                <View style={{width: '100%', maxWidth: 600, justifyContent: 'center'}}>

                </View>
                <PageTitle style={{color: textColors.lightBackground}}>{exercise.title}</PageTitle>

                {Platform.select({
                    web: () => (
                        <span
                            dangerouslySetInnerHTML={{__html: exercise.description}}
                            style={{marginTop: 10}}
                        />
                    ),
                    default: () => (
                        <HTML
                            style={{flex: 1}}
                            html={exercise.description}
                            imagesMaxWidth={Dimensions.get("window").width}
                        />
                    ),
                })()}
            </ScrollView>

            {showVideo &&
            <View style={styles.videoContainer}
            >
                <Video
                    ref={videoElementRef}
                    style={styles.videoElement}
                    source={{uri: exercise.videoURL}}
                    rate={1.0}
                    volume={1.0}
                    isMuted={false}
                    resizeMode="cover"
                    shouldPlay
                    isLooping
                />
                <View style={styles.videoControls}>
                    <TouchableOpacity
                        onPress={() => videoElementRef.current.presentFullscreenPlayer()}>
                        <Ionicons
                            name="ios-expand"
                            color="white"
                            size={32}
                        />
                    </TouchableOpacity>
                    <TouchableOpacity
                        onPress={() => {
                            videoElementRef.current.stopAsync();
                            setShowVideo(false);
                        }}>
                        <Ionicons
                            name="ios-close"
                            color="white"
                            size={32}
                            style={{marginStart: 32, marginEnd: 16}}
                        />
                    </TouchableOpacity>
                </View>
            </View>
            }

        </View>

    );
};

export default ExerciseDetailScreen;

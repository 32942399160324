import React from "react";
import FormControl from "./FormControl";
import TextInputView from "./TextInputView";
import { TextInputProps } from "react-native";

const PasswordInputField: React.FC<Props> = ({
  label,
  placeholder,
  onChange,
  ...props
}) => (
  <FormControl label={label} style={{ width: "100%" }}>
    <TextInputView
      secureTextEntry={true}
      placeholder={placeholder}
      autoCompleteType="password"
      onChange={onChange}
      {...props}
    />
  </FormControl>
);

export default PasswordInputField;

interface Props extends TextInputProps {
  label: string;
  placeholder: string;
}

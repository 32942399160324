import React from "react";
import FormControl from "./FormControl";
import { TextInputProps } from "react-native";
import TextInputView from "./TextInputView";

const EmailInputField: React.FC<Props> = ({
  label,
  onChange,
  autoCorrect = false,
  autoCapitalize = "sentences",
  placeholder = "",
  ...props
}) => (
  <FormControl label={label} style={{ width: "100%" }}>
    <TextInputView
      autoCompleteType="email"
      autoCorrect={autoCorrect}
      autoCapitalize={autoCapitalize}
      placeholder={placeholder}
      {...props}
    />
  </FormControl>
);

export default EmailInputField;

interface Props extends TextInputProps {
  label: string;
  autoCorrect?: boolean;
  placeholder: string;
  light?: boolean;
}

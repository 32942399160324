import React from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import WorkoutPreviewCard from "../../components/WorkoutPreviewCard";
import { StackActions, useNavigation } from "@react-navigation/native";
import { programs } from "./mockData";
import PageTitle from "../../components/text/PageTitle";
import {useSafeArea} from "react-native-safe-area-context";

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
  },
});

const ProgramScreen = () => {
  const navigation = useNavigation();
  const insets = useSafeArea();
  return (
    <ScrollView style={[styles.container, {paddingTop: insets.top, paddingBottom: insets.bottom}]}>
      {programs.map((program) => (
        <View key={program.id}>
          <PageTitle>{program.day}</PageTitle>
          <WorkoutPreviewCard
            title={program.title}
            source={program.imagePath}
            text={[...program.workouts]
              .map((workout) => workout.tag)
              .join(", ")}
            numOfLines={2}
            onPress={() =>
              navigation.dispatch(
                StackActions.push("Workout", { id: program.id })
              )
            }
            style={{ marginBottom: 16 }}
          />
        </View>
      ))}
    </ScrollView>
  );
};

export default ProgramScreen;

import React from "react";
import { Image, StyleSheet, Text, View } from "react-native";
import GradientImage from "../GradientImage";

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "auto",
    backgroundColor: "#0E1129"
  },
  image: {
    width: "100%",
    resizeMode: "contain",
    height: 250,
  },
  currentExercise: {
    marginTop: 16,
    color: "#255068",
    fontSize: 19,
  },
  exerciseTitle: {
    color: "#255068",
    fontSize: 42,
  },
  exerciseTime: {
    color: "#00EBA9",
    fontSize: 56,
  },
  typeContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  typeIcon: {},
  typeText: {
    color: "#9B9B9B",
    marginHorizontal: 6,
    fontSize: 19,
  },
});

const WorkingExercise = () => {
  return (
    <View style={styles.container}>
      <GradientImage />
      <Text style={styles.currentExercise}>1/2</Text>
      <Text style={styles.exerciseTitle}>Rainbow Plank</Text>
      <Text style={styles.exerciseTime}>00:42</Text>
      <View style={styles.typeContainer}>
        <Image
          source={require("../../assets/bodyweight.png")}
          style={styles.typeIcon}
        />
        <Text style={styles.typeText}>Bodyweight</Text>
      </View>
    </View>
  );
};

export default WorkingExercise;

import React, {Fragment, useContext} from 'react';
import {TouchableOpacity, ViewProps} from "react-native";
import backArrow from '../assets/arrow_left_white.png';
import {Image} from "react-native";
import {StyleSheet} from "react-native";
import { NavigationContext } from '@react-navigation/native';
import {useSafeArea} from "react-native-safe-area-context";


const baseStyles = StyleSheet.create({
    root: {
        position:"absolute",
        top: 8,
        start: 20,
    }
})

const BackButton = ({style}: ViewProps) => {
    const navigation = useContext(NavigationContext);
    const insets = useSafeArea();
    return (
        <Fragment>
            {navigation.canGoBack() &&
            <TouchableOpacity
                onPress={() => navigation.goBack()}
                style={[baseStyles.root, {paddingTop:insets.top},style]}
            >
                <Image source={backArrow} style={{width: 24, height:24}}/>
            </TouchableOpacity>
            }
        </Fragment>)
}

export default BackButton;

import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBLgzzK9yij7IPIwbSfgfY7kmM2frIVEgU",
  authDomain: "myfitworld-stage.firebaseapp.com",
  databaseURL: "https://myfitworld-stage.firebaseio.com",
  projectId: "myfitworld-stage",
  storageBucket: "myfitworld-stage.appspot.com",
  messagingSenderId: "507705216004",
  appId: "1:507705216004:web:619ea9d50c9729e8917951",
};

export const recaptchaVerifier = (container: any | string) =>
  new firebase.auth.RecaptchaVerifier(container);
export const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
export const app = firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const firestore = firebase.firestore();

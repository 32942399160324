import React from "react";
import {
  TouchableOpacity,
  View,
  Image,
  StyleSheet,
  ImageURISource,
  Text,
} from "react-native";
import Title from "./Title";
import { Ionicons } from "@expo/vector-icons";
import WorkoutDescription from "./text/WorkoutDescription";

const styles = StyleSheet.create({
  container: {
    padding: 8,
    display: "flex",
    flexDirection: "row",
  },
  imageStyle: {
    height: 80,
    resizeMode: "contain",
    width: 150,
    marginEnd: 8,
  },
  description: {
    display: "flex",
    flex: 1,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
});

const ImageListItem: React.FC<Props> = ({
  title,
  image,
  description,
  onPress,
  children,
}) => {
  return (
    <TouchableOpacity style={styles.container} onPress={onPress}>
      <Image source={image} style={styles.imageStyle} />
      <View style={styles.description}>
        <View style={styles.header}>
          <Title title={title || "Title"} size="medium" color="light" />

          <Ionicons name="ios-arrow-forward" color="black" size={16} />
        </View>
        <WorkoutDescription>{description}</WorkoutDescription>
        {children}
      </View>
    </TouchableOpacity>
  );
};

export default ImageListItem;

interface Props {
  title: string;
  image: ImageURISource;
  description: string;
  onPress: () => void;
}

export default {
  primary: "#01E8A7",
  secondary: "#01E8A7",
  accent: "#FFFFFF",
  background: "#0E1129",
  card: "#0E1129",
  text: "#fff",
  error: "#FF4800",
  disabled: "#E3EFEE",
};

import React, {ReactElement} from "react";
import {
    View,
    Image,
    TouchableOpacity,
    StyleSheet,
    ViewStyle,
    ImageStyle,
    TextStyle,
    ImageSourcePropType,
} from "react-native";
import PageTitle from "./text/PageTitle";
import BodyText from "./text/BodyText";

// import gradientImage from '../assets/gradient.png';

const aspectRatio = 1920 / 1080;
const styles = StyleSheet.create({
    container: {
        alignItems: 'center'
    },
    view: {
        minWidth: 320,
        maxWidth: 600,
        width: '100%',
        minHeight: 180,
        alignItems: "flex-start",
        justifyContent: 'space-between',
        shadowColor: "black",
        shadowOffset: {width: 0, height: 2},
        shadowOpacity: 0.2,
        elevation: 1,
        borderRadius: 4,
        aspectRatio,
    },
    backgroundImage: {
        ...StyleSheet.absoluteFillObject,
        borderRadius: 4,
        width: "100%",
        height: "100%",
    },
    gradientImage: {
        ...StyleSheet.absoluteFillObject,
        borderRadius: 4,
        width: "100%",
        height: "100%",
    },
    textcontainer: {
        width: '100%',
        display: 'flex',
        flexDirection: "row",
        paddingHorizontal: 16,
        marginBottom: 12,
        justifyContent: 'space-between'
    },
    arrow: {
        marginLeft: 2,
        marginRight: 2,
        alignSelf: "center",
        opacity: 0.6,
    },
});

const WorkoutPreviewCard: React.FC<Props> = ({
                                                 source,
                                                 title,
                                                 text,
                                                 onPress,
                                                 style,
                                                 viewStyle,
                                                 imageStyle,
                                             }) => (
    <TouchableOpacity disabled={!onPress} onPress={onPress} style={[styles.container, style]}>
        <View style={[styles.view, viewStyle]}>
            <Image source={source} style={[styles.backgroundImage, imageStyle]}/>
            <Image
                source={require("../assets/gradient.png")}
                style={[styles.gradientImage, imageStyle]}
            />
            <View style={{marginStart: 12, marginTop: 12}}>
                <PageTitle style={{
                    fontWeight: 'bold', shadowColor: "black",
                    shadowOffset: {width: 0, height: 2},
                    shadowOpacity: 0.2,
                }}>{title}</PageTitle>
            </View>
            <View style={{marginStart: 12, marginTop: 12, marginBottom:12}}>
                {text && (
                    <BodyText style={{fontSize: 14}}>
                        {text}
                    </BodyText>
                )}
            </View>
        </View>
    </TouchableOpacity>
);

export default WorkoutPreviewCard;

interface Props {
    source: ImageSourcePropType;
    title: string;
    text: string;
    numOfLines: number;
    onPress: () => void;
    style?: ViewStyle;
    viewStyle?: ViewStyle;
    imageStyle?: ImageStyle;
    titleStyle?: TextStyle;
    textStyle?: TextStyle;
    children?: ReactElement;
}

import BodyText from "./BodyText";
import React from "react";
import textColors from "../../colors/textColors";

const ButtonLabel = ({ children }) => (
  <BodyText
    style={{
      color: textColors.lightBackground,
      fontSize: 22,
    }}
  >
    {children}
  </BodyText>
);

export default ButtonLabel;

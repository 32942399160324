import React, {PropsWithChildren} from "react";
import {Animated, Dimensions, Image, StyleSheet, Text, View} from "react-native";
import {Program} from "../screens/program/mockData";
import WorkoutDescription from "./text/WorkoutDescription";
import PageTitle from "./text/PageTitle";
import {useSafeArea} from "react-native-safe-area-context";
import Subtitle from "./text/Subtitle";

const styles = StyleSheet.create({
    headerImage: {
        height: 240,
        resizeMode: "cover",
        width: "100%",
    },
    workoutHeaderSmall: {
        paddingBottom: 8,
    },
    text: {
        position: "absolute",
        top: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    gradientImage: {
        ...StyleSheet.absoluteFillObject,
        borderRadius: 4,
        width: "100%",
        height: "100%",
    },
});

const HEADER_MAX_HEIGHT = 240;
const HEADER_MIN_HEIGHT = 90;
const HEADER_SCROLL_DISTANCE = HEADER_MAX_HEIGHT - HEADER_MIN_HEIGHT;

const WorkoutHeader = ({scrollOffsetY, program}: Props) => {
    const insets = useSafeArea();

    const headerHeight = scrollOffsetY.interpolate({
        inputRange: [0, HEADER_SCROLL_DISTANCE],
        outputRange: [HEADER_MAX_HEIGHT, HEADER_MIN_HEIGHT],
        extrapolate: 'clamp',
    });

    const imageOpacity = scrollOffsetY.interpolate({
        inputRange: [0, HEADER_SCROLL_DISTANCE / 2, HEADER_SCROLL_DISTANCE],
        outputRange: [1, 0.5, 0],
        extrapolate: 'clamp',
    });

    const titleOpacity = scrollOffsetY.interpolate({
        inputRange: [0, HEADER_SCROLL_DISTANCE / 1.4, HEADER_SCROLL_DISTANCE],
        outputRange: [0, 0, 1],
        extrapolate: 'clamp',
    });

    const imageTranslate = scrollOffsetY.interpolate({
        inputRange: [0, HEADER_SCROLL_DISTANCE],
        outputRange: [0, -50],
        extrapolate: 'clamp',
    });

    return (
        <Animated.View style={{height: headerHeight}}>
            <Animated.Image
                style={[
                    styles.headerImage,
                    {opacity: imageOpacity, transform: [{translateY: imageTranslate}]},
                ]}
                source={program.workouts[0].exercise.imagePath}
            />
            <Image
                source={require("../assets/gradient-full.png")}
                style={[styles.gradientImage]}
            />
            <Animated.View style={[styles.text, styles.workoutHeaderSmall, {paddingTop: insets.top, opacity: titleOpacity}]}>
                <Subtitle>{program.title}</Subtitle>
            </Animated.View>

            <Animated.View style={[styles.text, {opacity: imageOpacity}]}>

                <View
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <WorkoutDescription>Thursday 05/17/2020</WorkoutDescription>
                    <PageTitle>{program.title}</PageTitle>
                    <WorkoutDescription>
                        25 minutes - 3 rounds - 300Kcal
                    </WorkoutDescription>
                    <Text style={{color: "white"}}>You will need: Barbell</Text>
                </View>
            </Animated.View>
        </Animated.View>
    );
};

export default WorkoutHeader;

interface Props {
    program: Program;
    scrollOffsetY: Animated.Value;
}

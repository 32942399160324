import React, { useState } from "react";
import { Button, Text, TextInput, View, StyleSheet, Image } from "react-native";
import { ActionType, useAuthContext } from "../providers/AuthContextProvider";
import { auth, facebookAuthProvider } from "../firebase";
import EmailInputField from "../components/form/EmailInputField";
import PasswordInputField from "../components/form/PasswordInputField";
import PhoneNumberLogin from "../components/login/PhoneNumberLogin";
import FacebookLoginButton from "../components/login/FacebookLoginButton";
const logo = require("../assets/logo512.png");

const styles = StyleSheet.create({
  container: {
    display: "flex",
    alignItems: "center",
    // justifyContent: "center",
    flex: 1,
  },
  input: {
    backgroundColor: "rgba(255, 255, 255, 0.75)",
    borderWidth: 1,
    borderColor: "#D5D5D5",
    borderRadius: 3,
    height: 55,
    margin: 8,
  },
  logo: {
    height: 80,
    width: 80,
  },
});

const SignInScreen = () => {
  const [authCtxt, dispatchAuthCtx] = useAuthContext();
  const [usernameValue, setUsernameValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");

  return (
    <View style={styles.container}>
      <Image source={logo} style={styles.logo} />
      <EmailInputField
        label="Email"
        placeholder="Email"
        onChange={(e) => {
          console.log(e);
        }}
        onChangeText={(username) => setUsernameValue(username)}
      />
      <PasswordInputField
        label="Password"
        placeholder="Password"
        onChangeText={(password) => setUsernameValue(password)}
      />
      {/*<PhoneNumberLogin/>*/}
      <Button
        title="Sign in"
        onPress={async () => {
          const authResult = await auth
            .signInWithEmailAndPassword(usernameValue, passwordValue)
            .then((user) => {
              dispatchAuthCtx({
                type: ActionType.SIGN_IN,
                token: "dummy_token",
              });
            })
            .catch(async (error) => {
              if (error.code === "auth/user-not-found") {
                auth
                  .createUserWithEmailAndPassword(usernameValue, passwordValue)
                  .then((_) =>
                    auth.signInWithEmailAndPassword(
                      usernameValue,
                      passwordValue
                    )
                  )
                  .then((user) => {
                    dispatchAuthCtx({
                      type: ActionType.SIGN_IN,
                      token: "dummy_token",
                    });
                  })
                  .catch((err) => console.log(err));
              } else {
                console.log(error.message);
              }
            });
          console.log(authResult);
        }}
      />
      <FacebookLoginButton
        onPress={() => {
          auth
            .signInWithPopup(facebookAuthProvider)
            .then((user) => console.log(user))
            .catch((err) => console.log(err));
        }}
      />
    </View>
  );
  // return (
  //   <View style={styles.container}>
  //     <Image source={logo} style={styles.logo} />
  //   </View>
  // );
};

export default SignInScreen;

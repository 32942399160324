import React from 'react';
import {Button, View} from 'react-native';
import {ActionType, useAuthContext} from "../providers/AuthContextProvider";

const ProfileScreen = ({navigation, route}) => {
    const [autCtx, dispatchAuthCtx] = useAuthContext();
    // const name = route.params.name;
    return (
        <View>
            <Button
                title={`Go to Home`}
                onPress={() =>
                    navigation.navigate('Home')
                }
            /><Button
            title={`Sign out`}
            onPress={() =>
                dispatchAuthCtx({type:ActionType.SIGN_OUT})
            }
        />
        </View>
    );
}

export default ProfileScreen;

import React from "react";
import {StyleSheet, Text, TextStyle} from "react-native";
import BodyText from "./text/BodyText";

const styles = StyleSheet.create({
    title: {
        marginVertical: 8,
        fontWeight: "bold",
    },
    small: {
        fontSize: 12,
    },
    medium: {
        fontSize: 18,
    },
    large: {
        fontSize: 32,
    },
    dark: {
        color: "#000",
    },
    light: {
        color: "#fff",
    },
});

const Title: React.FC<Props> = ({title, size, style, color = "dark"}) => {
    return (
      <BodyText>
        <Text
          style={[
            styles.title,
            size === "small"
              ? styles.small
              : size === "medium"
              ? styles.medium
              : styles.large,
            color === "dark" ? styles.dark : styles.light,
            style,
          ]}
        >
          {title}
        </Text>
      </BodyText>
    );
};

export default Title;

interface Props {
    title: string;
    size: Size;
    style?: TextStyle;
    color?: Color;
}

export type Size = "small" | "medium" | "large";
type Color = "light" | "dark";

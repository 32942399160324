import "react-native-gesture-handler";
import React, {useEffect, Fragment} from "react";
import {DefaultTheme, NavigationContainer} from "@react-navigation/native";
import HomeScreen from "./src/screens/HomeScreen";
import {createStackNavigator} from "@react-navigation/stack";
import ProfileScreen from "./src/screens/Profile";
import SignInScreen from "./src/screens/SignInScreen";
import AuthContextProvider, {
    useAuthContext,
} from "./src/providers/AuthContextProvider";
import ForgotPasswordScreen from "./src/screens/ForgotPasswordScreen";
import "./src/firebase";
import * as Analytics from "expo-firebase-analytics";
import ExerciseDetailScreen from "./src/screens/program/ExerciseDetailScreen";
import WorkoutScreen from "./src/screens/program/WorkoutScreen";
import {SafeAreaProvider} from "react-native-safe-area-context";

import {Alert, StatusBar} from "react-native";
import * as Updates from "expo-updates";
import {UpdateEventType} from "expo-updates";
import ExerciseScreen from "./src/screens/program/ExerciseScreen";
import ProgramScreen from "./src/screens/program/ProgramScreen";
import colors from "./src/colors/colors";

// Get the current screen from the navigation state
function getActiveRouteName(navigationState) {
    if (!navigationState) return null;
    const route = navigationState.routeNames[navigationState.index];
    // Parse the nested navigators
    if (route.routes) return getActiveRouteName(route);
    return route;
}

const Stack = createStackNavigator();


const MFWTheme = {
    ...DefaultTheme,
    colors: {
        ...DefaultTheme.colors,
        ...colors
    },
};

const AppWrapper = () => {
    const authCtx = useAuthContext()[0];

    return (
        <Fragment>
            <StatusBar barStyle="light-content" backgroundColor={colors.background}/>
            {!authCtx.isSignout ? (
                <NavigationContainer
                    theme={MFWTheme}
                    onStateChange={(state) => {
                        const currentScreen = getActiveRouteName(state);
                        console.log("Entered screen", currentScreen);
                        // Update Firebase with the name of your screen
                        Analytics.setCurrentScreen(currentScreen).catch((e) =>
                            console.error('analytics error', e)
                        );
                    }}

                >
                    <Stack.Navigator screenOptions={{headerShown: false}}>
                        <Stack.Screen name="Programs" component={ProgramScreen}/>
                        <Stack.Screen name="Profile" component={ProfileScreen}/>
                        <Stack.Screen name="Workout" component={WorkoutScreen}/>
                        <Stack.Screen name="Exercise" component={ExerciseDetailScreen}/>
                        <Stack.Screen name="ExerciseDetail" component={ExerciseScreen}/>
                        <Stack.Screen
                            name="Home"
                            component={HomeScreen}
                            options={{title: "Welcome"}}
                        />
                    </Stack.Navigator>
                </NavigationContainer>
            ) : (
                <NavigationContainer
                    onStateChange={(state) => {
                        const currentScreen = getActiveRouteName(state);
                        console.log("Entered screen", currentScreen);
                        // Update Firebase with the name of your screen
                        Analytics.setCurrentScreen(currentScreen).catch((e) =>
                            console.error('error logging event', e)
                        );
                    }}
                >
                    <Stack.Navigator>
                        <Stack.Screen name="SignIn" component={SignInScreen}/>
                        <Stack.Screen
                            name="Forgot password"
                            component={ForgotPasswordScreen}
                        />
                    </Stack.Navigator>
                </NavigationContainer>
            )}
        </Fragment>
    );

};

const App = () => {
    useEffect(() => {
        const evtSubscription = Updates.addListener((event) => {
            switch (event.type) {
                case UpdateEventType.UPDATE_AVAILABLE:
                    Alert.alert(
                        "New version is available 🙌",
                        "Woohoo. Click OK if you want to update immediately or Cancel to update on next launch.",
                        [
                            {
                                text: "Cancel",
                                onPress: () => {
                                },
                                style: "cancel",
                            },
                            {
                                text: "OK",
                                onPress: () =>
                                    Updates.reloadAsync()
                                        .then((result) =>
                                            console.log("done updating and reloading", result)
                                        )
                                        .catch((err) =>
                                            console.log("error reloading the app", err)
                                        ),
                                style: "default",
                            },
                        ],
                        {cancelable: false}
                    );
                    break;
                case UpdateEventType.NO_UPDATE_AVAILABLE:
                    console.log("There are no new updates available", event);
                    break;
                case UpdateEventType.ERROR:
                    console.log("Failed to fetch updates", event.message);
                    break;
            }
        });
        return () => evtSubscription.remove();
    }, []);
    return (
        <SafeAreaProvider>
            <AuthContextProvider>
                <AppWrapper/>
            </AuthContextProvider>
        </SafeAreaProvider>
    );
};

export default App;

import React from "react";
import {programs} from "../../../screens/program/mockData";
import {StackActions} from "@react-navigation/native";
import {Animated, ScrollView, StyleSheet} from "react-native";
import {StackNavigationProp} from "@react-navigation/stack";
import ImageListItem from "../../ImageListItem";
import colors from "../../../colors/colors";

const styles = StyleSheet.create({
  container: {
    padding: 16,
    backgroundColor: colors.background,
  },
  imageStyle: {
    height: 240,
    resizeMode: "contain",
    width: "100%",
  }
});
const WorkoutOverview: React.FC<Props> = ({ programId, scrollOffsetY, navigation }) => {
  const workouts = programs.find((program) => program.id === programId)
    .workouts;

  return (
    <ScrollView style={styles.container}
                onScroll={Animated.event([{ nativeEvent: { contentOffset: { y: scrollOffsetY } } }])}
                scrollEventThrottle={16}>
      {workouts.map((workout) => (
        <ImageListItem
          title={workout.exercise.title}
          image={workout.exercise.imagePath}
          description={workout.howMuch}
          onPress={() => {
            navigation.dispatch(
              StackActions.push("Exercise", {
                programId: programId,
                workoutId: workout.id,
              })
            );
          }}
          key={workout.id}
        />
      ))}
      {workouts.map((workout) => (
          <ImageListItem
              title={workout.exercise.title}
              image={workout.exercise.imagePath}
              description={workout.howMuch}
              onPress={() => {
                navigation.dispatch(
                    StackActions.push("Exercise", {
                      programId: programId,
                      workoutId: workout.id,
                    })
                );
              }}
              key={workout.id}
          />
      ))}
      {workouts.map((workout) => (
          <ImageListItem
              title={workout.exercise.title}
              image={workout.exercise.imagePath}
              description={workout.howMuch}
              onPress={() => {
                navigation.dispatch(
                    StackActions.push("Exercise", {
                      programId: programId,
                      workoutId: workout.id,
                    })
                );
              }}
              key={workout.id}
          />
      ))}
    </ScrollView>
  );
};

export default WorkoutOverview;

interface Props {
  programId: number;
  navigation: StackNavigationProp<any>;
  scrollOffsetY: Animated.Value;
}

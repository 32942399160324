import React from "react";
import {
  TextInput,
  View,
  StyleSheet,
  ViewStyle,
  TextInputProps,
} from "react-native";

const styles = StyleSheet.create({
  input: {
    backgroundColor: "rgba(255, 255, 255, 0.75)",
    borderWidth: 1,
    borderColor: "#D5D5D5",
    borderRadius: 3,
    height: 55,
  },
  inputText: {
    flex: 1,
    marginLeft: 8,
    marginRight: 8,
  },
});

const TextInputView: React.FC<Props> = ({
  viewStyle,
  inputStyle,
  onChange,
  ...props
}) => (
  <View style={[styles.input, viewStyle]}>
    <TextInput
      style={[styles.inputText, inputStyle]}
      onChange={onChange}
      {...props}
    />
  </View>
);

interface Props extends TextInputProps {
  viewStyle?: ViewStyle;
  inputStyle?: ViewStyle;
}

export default TextInputView;

import React, {useState} from "react";
import {StyleSheet, View} from "react-native";
import WorkingExercise from "../../components/exercise/WorkingExercise";
import Title from "../../components/Title";
import ExerciseRest from "../../components/exercise/ExerciseRest";
import NextRestExercise from "../../components/exercise/NextRestExercise";
import NextWorkingExercise from "../../components/exercise/NextWorkingExercise";
import {useSafeArea} from "react-native-safe-area-context";
import PageTitle from "../../components/text/PageTitle";
import Subtitle from "../../components/text/Subtitle";
import BackButton from "../../components/BackButton";

const styles = StyleSheet.create({
    container: {
        backgroundColor: "#0E1129",
        height: "100%",
        display: "flex",
        justifyContent: "flex-end",
    },
});

const ExerciseScreen = () => {
    const [showRest, setShowRest] = useState(false);
    const insets = useSafeArea();
    return (
        <View style={[styles.container, {paddingTop: insets.top}]}>
            <Subtitle
                style={{alignSelf: "center", marginBottom: 16}}
            >Round 1/2</Subtitle>

            <BackButton />

            {showRest ? <ExerciseRest/> : <WorkingExercise/>}
            <View style={{flex: 1}}/>
            {!showRest ? (
                <NextRestExercise
                    onNext={() => setShowRest((prevState) => !prevState)}
                />
            ) : (
                <NextWorkingExercise
                    onNext={() => setShowRest((prevState) => !prevState)}
                />
            )}

        </View>
    );
};

export default ExerciseScreen;

import React, {useMemo} from "react";
import {Animated, StyleSheet, TouchableOpacity, View} from "react-native";
import WorkoutOverview from "../../components/exercise/workout/WorkoutOverview";
import WorkoutHeader from "../../components/WorkoutHeader";
import {StackActions} from "@react-navigation/native";
import ButtonLabel from "../../components/text/ButtonLabel";
import {programs} from "./mockData";
import colors from "../../colors/colors";
import BackButton from "../../components/BackButton";

const styles = StyleSheet.create({
    screen: {
        backgroundColor: "#0E1129",
    },
    headerImage: {
        height: 240,
        resizeMode: "cover",
        width: "100%",
    },
    button: {
        width: "100%",
        height: 70,
        paddingBottom: 16,
        backgroundColor: colors.primary,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
});

// const tabs: TabModel[] = [
//   {
//     component: WorkoutPage,
//     name: "Workout",
//   },
// ];

const WorkoutScreen = ({route, navigation}) => {
    const {id: programId} = route.params;
    const program = programs.find((program) => program.id === programId)
    const scrollOffsetY = useMemo(() => new Animated.Value(0), []);
    return (
        <View style={{flex: 1, justifyContent: 'space-between'}}>

            <WorkoutHeader program={program} scrollOffsetY={scrollOffsetY} />

            <WorkoutOverview programId={programId} scrollOffsetY={scrollOffsetY} navigation={navigation}/>
            <TouchableOpacity
                style={styles.button}
                onPress={() =>
                    navigation.dispatch(StackActions.push("ExerciseDetail", {id: 1}))
                }
            >
                <ButtonLabel>Start</ButtonLabel>
            </TouchableOpacity>
            <BackButton />
        </View>
    );
};

export default WorkoutScreen;

import React from "react";
import { View, StyleSheet, Text, ViewStyle, TextStyle } from "react-native";

const styles = StyleSheet.create({
  formControl: {
    marginVertical: 11,
  },
  label: {
    color: "#000",
    fontSize: 14,
    marginBottom: 8,
    fontWeight: "bold",
  },
  horizontal: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
});
const FormControl: React.FC<Props> = ({
  label,
  style,
  labelStyle,
  horizontal,
  light,
  children,
}) => {
  return (
    <View
      style={[styles.formControl, horizontal ? styles.horizontal : null, style]}
    >
      <Text
        style={[
          styles.label,
          horizontal ? { marginBottom: 0 } : null,
          light ? { color: "#fff" } : null,
          labelStyle,
        ]}
      >
        {label}
      </Text>
      {children}
    </View>
  );
};

export default FormControl;

interface Props {
  label: string;
  style?: ViewStyle;
  labelStyle?: TextStyle;
  horizontal?: boolean;
  light?: boolean;
}

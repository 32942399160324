import React from "react";
import { Image, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import NextExercise from "./NextExercise";

const styles = StyleSheet.create({
  nextExercise: {
    backgroundColor: "white",
    height: "15%",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 16,
  },
  arrow: {
    width: 24,
    height: 24,
  },
});

const NextRestExercise: React.FC<Props> = ({ onNext }) => (
  <NextExercise onNext={onNext} isNextWorkingExercise={false} />
);

export default NextRestExercise;

interface Props {
  onNext: () => void;
}

import React from "react";
import { Image, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import {useSafeArea} from "react-native-safe-area-context";

const styles = StyleSheet.create({
  nextExercise: {
    backgroundColor: "white",
    height: "15%",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 16,
  },
  arrow: {
    width: 24,
    height: 24,
  },
});

const NextExercise: React.FC<Props> = ({ isNextWorkingExercise, onNext }) => {
  const insets = useSafeArea();
  return (
    <TouchableOpacity style={[styles.nextExercise, {paddingBottom: insets.bottom}]} onPress={onNext}>
      <View>
        <Text style={{ fontSize: 16, color: "#9B9B9B", fontWeight: "bold" }}>
          Next up:
        </Text>
        <Text style={{ fontSize: 24, fontWeight: "bold" }}>30 sec break</Text>
      </View>
      {isNextWorkingExercise && (
        <Image
          source={require("../../assets/workout-mock.png")}
          style={{ height: "70%", width: "30%", resizeMode: "contain" }}
        />
      )}
      <Image
        source={require("../../assets/ic_arrow_forward.png")}
        style={styles.arrow}
      />
    </TouchableOpacity>
  );
};

export default NextExercise;

interface Props {
  onNext: () => void;
  isNextWorkingExercise: boolean;
}

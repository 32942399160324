import React from "react";
import { StyleSheet, Text, View } from "react-native";
import Title from "../Title";
import ExerciseRestCounter from "./ExerciseRestCounter";

const styles = StyleSheet.create({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  title: {
    color: "#255068",
    alignSelf: "center",
  },
  counter: {
    width: 250,
    height: 250,
    backgroundColor: "#00EBA9",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 199,
    marginVertical: 70,
  },
  counterText: {
    fontSize: 60,
  },
});

const ExerciseRest = () => {
  return (
    <View style={styles.container}>
      <Title title="GET READY!" size="large" style={styles.title} />
      <ExerciseRestCounter />
    </View>
  );
};

export default ExerciseRest;

import React from "react";
import NextExercise from "./NextExercise";

const NextWorkingExercise: React.FC<Props> = ({ onNext }) => (
  <NextExercise onNext={onNext} isNextWorkingExercise={true} />
);

export default NextWorkingExercise;

interface Props {
  onNext: () => void;
}

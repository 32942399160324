import React, { PropsWithChildren } from "react";
import { StyleSheet, Text, TextProps } from "react-native";
import textColors from "../../colors/textColors";

const styles = StyleSheet.create({
  bodyText: {
    fontSize: 12,
    color: textColors.primary,
  },
});

const BodyText = ({ children, style }: PropsWithChildren<TextProps>) => (
  <Text style={[styles.bodyText, style]}>{children}</Text>
);

export default BodyText;

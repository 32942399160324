import React, {createContext, Reducer, useEffect, useReducer} from "react";
import {AsyncStorage} from "react-native";
import * as Analytics from 'expo-firebase-analytics';

const AuthContext = createContext<[AuthState, (action: Action) => void]>(undefined);

export enum ActionType {
    RESTORE_TOKEN,
    SIGN_IN,
    SIGN_OUT
}

export interface Action {
    type: ActionType;
    token?: string;
}

interface AuthState {
    userToken?: string;
    isLoading?: boolean;
    isSignout?: boolean;
}


const AuthContextProvider = ({children}) => {
    const [state, dispatch] = useReducer<Reducer<AuthState, Action>>(
        (prevState, action) => {
            switch (action.type) {
                case ActionType.RESTORE_TOKEN:
                    Analytics.logEvent('User_Restore_Token').catch((e) => console.error('Analytics event Log error', e));
                    Analytics.setUserId(action.token).catch((e) => console.error('cannot send id to analytics' , e));
                    return {
                        ...prevState,
                        userToken: action.token,
                        isLoading: false,
                    };
                case ActionType.SIGN_IN:
                    Analytics.logEvent('User_SignIn').catch((e) => console.error(e));
                    Analytics.setUserId(action.token).catch((e) => console.error('cannot send id to analytics' , e));
                    return {
                        ...prevState,
                        isSignout: false,
                        userToken: action.token,
                    };
                case ActionType.SIGN_OUT:
                    Analytics.logEvent('User_SignOut').catch((e) => console.error(e));
                    return {
                        ...prevState,
                        isSignout: true,
                        userToken: null,
                    };
            }
        },
        {
            isLoading: true,
            isSignout: false,
            userToken: null,
        }
    );

    useEffect(() => {
        // Fetch the token from storage then navigate to our appropriate place
        const bootstrapAsync = async () => {
            let userToken;

            try {
                userToken = await AsyncStorage.getItem('userToken');
            } catch (e) {
                // Restoring token failed
            }

            // After restoring token, we may need to validate it in production apps

            // This will switch to the App screen or Auth screen and this loading
            // screen will be unmounted and thrown away.
            dispatch({type: ActionType.RESTORE_TOKEN, token: userToken});
        };

        bootstrapAsync();
    }, []);


    return (
        <AuthContext.Provider value={[state,dispatch]}>{children}</AuthContext.Provider>
    )
}

export const useAuthContext = () => {
    const context = React.useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuthContext must be used within a AuthContextProvider');
    }
    return context;
};

export default AuthContextProvider;

import React, { Fragment } from "react";
import { StyleSheet, Text, View } from "react-native";

const styles = StyleSheet.create({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  counter: {
    width: 250,
    height: 250,
    backgroundColor: "#00EBA9",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 199,
    marginVertical: 70,
  },
  counterRingOne: {
      width: 370,
      height: 370,
      position: "absolute",
  },
  counterText: {
    fontSize: 60,
  },
});

const ExerciseRestCounter = () => {
  return (
    <View style={styles.container}>
      <View style={styles.counter}>
        <Text style={styles.counterText}>00:22</Text>
      </View>
    </View>
  );
};

export default ExerciseRestCounter;

import React, { Fragment } from "react";
import { Image, StyleSheet, View } from "react-native";
const styles = StyleSheet.create({
  image: {
    height: 240,
    resizeMode: "cover",
    width: "100%",
  },
  gradientImage: {
    ...StyleSheet.absoluteFillObject,
    borderRadius: 4,
    resizeMode: "cover",
    height: 240,
    width: "100%",
  },
});
const GradientImage = ({image}) => {
  return (
    <Fragment>
      <Image
        style={styles.image}
        source={image || require("../assets/workout-mock.png")}
      />
      <Image
        source={require("../assets/gradient-full.png")}
        style={[styles.gradientImage]}
      />
    </Fragment>
  );
};

export default GradientImage;

import React from 'react';
import {Button, View} from "react-native";
import {ActionType, useAuthContext} from "../providers/AuthContextProvider";

const ForgotPasswordScreen = () => {
    const [authCtxt, dispatchAuthCtx] = useAuthContext();

    console.log('in sign in flow')
    return (<View><Button title='Sign in' onPress={() => dispatchAuthCtx({type: ActionType.SIGN_IN, token: 'dummy_token'})} /></View>)
}

export default ForgotPasswordScreen;

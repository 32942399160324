import React from "react";
import { Text, TouchableOpacity, StyleSheet, Image } from "react-native";
import { Ionicons } from "@expo/vector-icons";
const styles = StyleSheet.create({
  button: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: 45,
    backgroundColor: "#4267B2",
  },
  label: {
    color: "white",
    fontSize: 18,
  },
});

const FacebookLoginButton = ({ onPress }) => {
  return (
    <TouchableOpacity onPress={onPress} style={styles.button}>
      <Ionicons name="facebook" color="white" size={16} />
      <Text style={styles.label}>Log in using facebook</Text>
    </TouchableOpacity>
  );
};

export default FacebookLoginButton;

import React from 'react';
import {Button, StyleSheet, Text, View} from "react-native";

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
    },
});

const HomeScreen = ({ navigation }) => {
    return (<View style={styles.container}>
        <Text>Hello</Text>
        <Button
            title="Go to Jane's profile"
            onPress={() =>
                navigation.navigate('Profile')
            }
        />
    </View>)
}



export default HomeScreen;

